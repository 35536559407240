.homePage {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #faf7f6;
}

.homePageNameHeading {
  font-size: calc(3.5rem + 2vmin);
  font-weight: 600;
  letter-spacing: -2px;
  text-transform: lowercase;
  text-decoration: none;
  color: #faf7f6;
}

@media only screen and (max-width: 600px) {
  .typeContainer {
    min-height: 4rem;
  }

  .homePageNameHeading {
    font-size: calc(2.5rem + 2vmin);
    padding-bottom: 0.5rem;
  }
}
