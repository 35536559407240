html {
  /* 62.5% of 16px browser font size is 10px - see 1rem trick */
  font-size: 62.5%;
  font-weight: 500;
  color: white;
}

html,
body,
#root,
.app {
  height: 100%;
  text-align: center;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #6536a1;
}

.app__footer {
  display: flex;
  justify-content: right;
  align-items: center;
  height: fit-content;
  padding: 0rem 1.5rem 1.5rem;
}

.app__footer-pill {
  padding: 7px;
  border: 1.5px solid white;
  border-radius: 5px;
  background-color: #703db4;
}

.app__footer-text {
  font-size: 1.25rem;
  font-weight: 600;
}
